.site-layout-content {
	min-height: 800px;
	padding: 24px;
}
.logo {
	float: left;
	width: 120px;
	height: 31px;
	margin: 16px 24px 16px 0;
	background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
	float: right;
	margin: 16px 0 16px 24px;
}
.header {
	display: flex;
	justify-content: space-between;
}
.menu {
	width: 80%;
}
